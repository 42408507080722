<template>
  <div>
    <ListingLayout
      :sub-screen="1"
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      no-filters
      show-date
      :date-validation="false"
      vertical-scroll
      @filterOption="onFiltersChange"
    >
      <!-- :date-custom-options="false" -->
      <template v-slot:state_of_charge="{ item }">
        <div>
          <span v-if="item.state_of_charge == 'N/A'">
            {{ item.state_of_charge }}
          </span>
          <span v-else> {{ item.state_of_charge }} % </span>
        </div>
      </template>
      <template v-slot:state_of_health="{ item }">
        <div>
          <span v-if="item.state_of_health == 'N/A'">
            {{ item.state_of_health }}
          </span>
          <span v-else> {{ item.state_of_health }} % </span>
        </div>
      </template>
      <template v-slot:start_soc="{ item }">
        <div>
          <span v-if="item.start_soc == 'N/A'">
            {{ item.start_soc }}
          </span>
          <span v-else> {{ item.start_soc }} % </span>
        </div>
      </template>
      <template v-slot:end_soc="{ item }">
        <div>
          <span v-if="item.end_soc == 'N/A'">
            {{ item.end_soc }}
          </span>
          <span v-else> {{ item.end_soc }} % </span>
        </div>
      </template>
      <template v-slot:duration="{ item }">
        <div>{{ item.duration }}</div>
      </template>
      <template v-slot:charge_gain="{ item }">
        <div>
          <span v-if="item.charge_gain == 'N/A'">
            {{ item.charge_gain }}
          </span>
          <span v-else> {{ item.charge_gain }} % </span>
        </div>
      </template>
      <template v-slot:energy_consumption="{ item }">
        <div>{{ item.energy_consumption }}</div>
      </template>

      <!-- add Filters -->
      <!-- <template #addFilters>
        <v-card v-if="authUser.isAdmin">
          
        </v-card>
      </template> -->
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <refresh-button
          :is-loading="isLoading"
          @reload="fetchData"
        />
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    refreshButton: () => import("@/components/base/RefreshButton.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      filters: {},
      subFilters: {},
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "batteryDiagnostics/getIsLoading",
      meta: "batteryDiagnostics/getMeta",
      list: "batteryDiagnostics/getBatteryDiagnostics",
      //
      headersList: "batteryDiagnostics/getHeaders",
      //
      batteryInfo: "batterries/batterriesItem",
    }),
    vehicle_type() {
      return this.$route.query.type;
    },
    _headers() {
      var headerArr = [];
      this.headersList.forEach((header) => {
        headerArr.push({
          text: this.$t(header.key),
          // text: header.key,
          align: "center",
          sortable: false,
          value: header.key,
          width: "10%",
        });
      });

      // return this.vehicle_type == "three_wheeler" ? headerArr : this.headers;
      return headerArr;
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  beforeDestroy() {
    this.$store.commit("batteryDiagnostics/SET_BATTERY_DIAGNOSTICS", []);
  },
  async mounted() {
    const params = {
      // tenant_id:
      //   this.authUser.isAdmin || this.authUser.user_nature == "fleet"
      //     ? this.$route.query.tenant_id
      //     : this.authUser.tenant_id,
      // // tenant_id: this.batteryInfo.tenant_id,
      tenant_id: this.batteryInfo.tenant_id,
      vehicle_id: this.$route.query.vehicle_id,
    };

    await this.$store.dispatch("batteryDiagnostics/headers", params);
  },
  methods: {
    async fetchData() {
      const imei = this.$route.params.imei;
      this.options.bmsId = this.$route.query.bmsId;
      this.options.imei = imei;
      if (this.vehicle_type == "three_wheeler") {
        await this.$store
          .dispatch("batteryDiagnostics/batteryTelematicList", this.options)
          .then(() => {});
      } else {
        await this.$store
          .dispatch("batteryDiagnostics/list", this.options)
          .then(() => {});
      }
    },
  },
};
</script>
